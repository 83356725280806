import React, { useState, useEffect} from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { Top } from "../Common/Header";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import './Schedule.css';

function Schedule ()  {
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();		
	useEffect(() => {
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, []);


	const location = useLocation();
  	const { course_name } = location.state;
  	const { course_id } = location.state;
  	const { qty } = location.state;
  	const { hours_ori } = location.state;
  	const { cost } = location.state;
  	const { typepay } = location.state;
  	const { modality_id } = location.state;
  	const { group_name } = location.state;
  	const { ubication } = location.state;
  	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);

	// config calendar
  	const [language, SetLanguage] = useState('es');
  	const [numonths, SetNumonths] = useState('3');
  	const [displayweek, SetDisplayweek] = useState(false);
  	const currentDate = new Date();
  	const currentHour = currentDate.getHours();
  	const currentMinute = currentDate.getMinutes();
  	useEffect(() => {
		addDays(currentDate, currentHour, currentMinute);
	}, []);
  	// var futureDate = new Date(); futureDate.setMonth(currentDate.getMonth() + 12);    // 365 days after currentDate 
  	var futureDate = new Date(new Date().getFullYear(), 11, 31); // last_day_year 
  	const [minDate, setMinDate] = useState(currentDate);  	
  	const [maxDate, setMaxDate] = useState(futureDate);
  	const [weekStart, setWeekStart] = useState('0');
  	const [disDays, setDisDays] = useState([]);//useState("2022-10-01T00:00");
  	const [disweekDays, setDisweekDays] = useState(['0']);
  	// actions
  	const [date_selected, setDateSelected] = useState("");
  	const [date_selected_mx, setDateSelectedMx] = useState("");
  	const [hour_selected, setHourSelected] = useState("");
  	const [hour_id_selected, setHourIdSelected] = useState("");
  	const [hours, setHours] = useState([{ name: "...", id: "" }]);
  	const [messageEmpty, setMessageEmpty] = useState("");
  	const [showmsgEmpty, setShowmsgEmpty] = useState(false);
  	const [disabled_next, setDisabledNext] = useState(true);
  	
  	useEffect(() => {
  		if(user.role == 3){
			var parts = user.expiration.split('-');
			var start_date = new Date(parts[0], parts[1] - 1, parts[2]); 
			const actual_date = new Date();
			const year = actual_date.getFullYear();
			var end_date = new Date(year, 11, 31);
			var dts = getDates(start_date, end_date);
			setDisDays(dts);
		}
	}, []);

  	Date.prototype.addDays = function(days) {
       var dat = new Date(this.valueOf())
       dat.setDate(dat.getDate() + days);
       return dat;
   }

   function getDates(startDate, stopDate) {
      let dateArray = new Array();
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(currentDate)
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    }
 

	const handleDay = (e) => {
		e.preventDefault();	
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		const dt = e.date.toISOString().split('T')[0];
		const dt_mx = e.date.toLocaleDateString("es-MX", options);
		setDateSelected(dt);
		setDateSelectedMx(dt_mx);
		if(dt !== "" || dt !== "0000-00-00") {
			UserService.getHoursFromDay(course_id, dt)
			.then(
				(response) => {	
					if (response.data.dates.length > 0) {
						setHours([{ name: "...", id: "" }]);
						setHours(response.data.dates.map((value) => ({ id: value, name: value })));
						setHourSelected("");
						setDisabledNext(true);
						setMessageEmpty("");
						setShowmsgEmpty(false);
					}else{
						setHours([]);
						setMessageEmpty("No se encontraron horas disponibles.");
						setShowmsgEmpty(true);
					}               
				},(error) => {
					const errorMessage = 
					(error.response && error.response.data && error.response.data.message) ||
					error.message || error.toString();

					setMessage(errorMessage);
					setShowmsg(true);
				}
			);
		}else{
			setMessage("Error en formato de fecha.");
			setShowmsg(true);
		}
	};

	const onChangeHourSelected = (e) => {
		const hour_selected = e.target[e.target.selectedIndex].text;
		setHourSelected(hour_selected);
		setDisabledNext(false);
		const hour_id_selected = e.target[e.target.selectedIndex].value;		
		setHourIdSelected(hour_id_selected);
	};

	function addDays(currentDate, currentHour, currentMinute) {
		//console.log("hour:"+currentHour+", minute:"+currentMinute)
		var days=3;
		if(currentHour<12){
			days=2;
		}
		var iend = days;
		var checkDay = new Date(currentDate);
		var theday = checkDay.getDay();
		if(theday==0 || theday==6){
				days+=1;
		}
		//console.log("td1:"+theday);
		for(let i=1; i <= iend;i++){						
			checkDay.setDate(checkDay.getDate() + 1);
			theday = checkDay.getDay();
			//console.log("td"+i+":"+theday);
			if(theday==0 || theday==6){
				days+=1;
			}
		}
		//console.log("days:"+days);
  		var currDate = new Date(currentDate);
  		currDate.setDate(currDate.getDate() + days);
  		setMinDate(currDate);  		  	
	}


	return (
		<div className="w-full min-h-screen mx-auto overflow-y-hidden pb-8">
			<Top />
			
			<div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8 my-6">
				<div  className="flex items-center border px-4 py-2 gap-2 bg-gray-50 rounded">
					<div className="w-full ">
						<hr className="border-t-4 border-red-600"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 1</h5>
						<p className="">Datos del registro</p>
					</div>
					<div className="w-full">
						<hr className="border-t-4"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 2</h5>
						<p className="">Agendar fecha</p>
					</div>
					<div className="w-full">
						<hr className="border-t-4"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 2</h5>
						<p className="">Resumen del registro</p>
					</div>
				</div>
			</div>

			<div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8 mt-6">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }

				<h2 className="text-2xl my-4">{modality_id < 3 ? "Curso" : "Renta"}: <strong className="">{course_name}</strong></h2>
				
				<div className="flex mx-auto p-2 gap-6">
					<div className="w-2/4">
						<div className="w-full border">
							<div className="flex items-center">
								<div className="rounded-full border h-10 w-10 justify-center items-center flex border-2 border-red-700 text-red-500 mr-4 relative">
									I
									{ !date_selected_mx &&(
										<span className="rounded-full h-3 w-3 bg-red-500 absolute animate-ping"></span>
									)}
								</div>
								<div>
									<h2 className="text-base font-bold">Elige una fecha:</h2>
									<p className="text-gray-700">Haz click en un día del calendario</p>
								</div>
								<div className="w-5 overflow-hidden inline-block ml-auto">
								 	<div className=" h-8 border-2 border-gray-300 rotate-45 transform origin-top-left "></div>
								</div>
							</div>
						</div>
						<div className="max-h-80 overflow-y-auto calendar-div">							
							<Calendar
								language={language}								
								minDate={minDate}
								maxDate={maxDate}
								weekStart={weekStart}
								disabledDays={disDays}
								disabledWeekDays={disweekDays}
								displayWeekNumber={displayweek}
								onDayClick={handleDay}								
							/>
						</div>
					</div>

					<div className="w-1/4">
						<div className="w-full border">
							<div className="flex items-center">
								<div className="rounded-full border h-10 w-10 justify-center items-center flex border-2 border-red-700 text-red-500 mr-4 relative">
									II
									{ !hour_selected && (
										<span className="rounded-full h-3 w-3 bg-red-500 absolute animate-ping"></span>
									)}
								</div>
								<div>
									<h2 className="text-base font-bold">Elige una hora:</h2>
									<p className="text-gray-700">Haz click en la de tu preferencia</p>
								</div>
							</div>
						</div>
						{ showmsgEmpty && <Message message={messageEmpty} type="warning" show={showmsgEmpty} /> }
						<select name="hour" size="10"
								className="px-4 py-1 w-full h-full overflow-hidden border hover:bg-gray-50 active:bg-blue-50 focus:outline-none "					
								onChange={onChangeHourSelected}>								
								{hours.map(item => (
						        <option key={Math.random()} value={item.name} className="border m-2 p-1 cursor-pointer">{item.name}</option>
						      	))}			      
						</select>						
					</div>

					<div className="w-1/4">
						<div className="w-full border">
							<div className="flex items-center">
								<div className="rounded-full border h-10 w-10 justify-center items-center flex border-2 border-red-700 text-red-500 mr-4">III</div>
								<div>
									<h2 className="text-base font-bold">Agendar fecha:</h2>
									<p className="text-gray-700">Revisa día y horario</p>
								</div>
							</div>
						</div>
						<div className="mx-auto max-w-screen-lg p-2 mt-4">
							<div>
								<h2 className="text-lg">Día seleccionado:</h2>					
								<div className="h-8 text-center border-2 border-red-700">									
									{ date_selected_mx }
								</div>
							</div>
							<div className="mt-4">						
								<h2 className="text-lg">Horario seleccionado:</h2>
								<div className="h-8 text-center border-2 border-red-700">									
									{ hour_selected }
								</div>							
							</div>					
						</div>
						
						{disabled_next ?
							<span></span>
							:
							<Link to="/confirmation"
								state={{ course_name: course_name, course_id: course_id, qty: qty, hours_ori: hours_ori, cost: cost, typepay: typepay, date_selected: date_selected, date_selected_mx: date_selected_mx, hour_selected: hour_selected, modality_id: modality_id, group_name: group_name, ubication: ubication }} 
								className="block mt-4 border bg-red-500 hover:bg-red-900 border-red-700 rounded-full text-white font-bold py-2 px-4">
								Siguiente →
							</Link>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Schedule;