import axios from 'axios';
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const register = (name, email, phone, password, password_confirmation, account, business, stps) => {
	return axios.post(API_URL + '/register', {
		name, email, phone, password, password_confirmation, account, business, stps
	});
};

const login = (email, password) => {
	return axios.post(API_URL + '/login', {
		email, password
	})
	.then((response) => {
		if(response.data.token) {
			localStorage.setItem("user", JSON.stringify(response.data));
		}
		return response.data;
	});
};

const logout = () => {
	localStorage.removeItem("user");
	window.location.reload(true);
};

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};


export default { register, login, logout, getCurrentUser };