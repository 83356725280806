import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const Message = (props) => {
   const [type, setType] = useState(props.type);
   
   if(type == "danger"){
      return (
         <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 my-4 rounded relative" role="alert">
            <svg className="fill-current h-6 w-6 mr-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>                
            <span className="block sm:inline">{ props.message}</span>
         </div>
      
      )
   }

   if(type == "success"){
      return (
         <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 my-4 rounded relative" role="alert">
            <svg className="fill-current h-6 w-6 mr-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>                
            <span className="block sm:inline">{ props.message}</span>
         </div>
      
      )
   }

   if(type == "warning"){
      return (
         <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-2 my-4 rounded relative" role="alert">
            <svg className="fill-current h-6 w-6 mr-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>                
            <span className="block sm:inline">{ props.message}</span>
         </div>
      
      )
   }
}
