import React, { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from '../../services/auth.service';

const required = (value) => {
	if(!value){
		return (
			<div className="text-sm text-red-500" role="alert">
				Este campo es requerido!
			</div>
		);
	}
};

const validateName = (value) => {
  	if (value.length < 3 || value.length > 60) {
    	return (
      	<div className="alert alert-danger" role="alert">
        		Escriba un nombre entre 3 y 60 caractéres.
      	</div>
    	);
  	}
};

const validEmail = (value) => {
  	if (!isEmail(value)) {
    	return (
      	<div className="alert alert-danger" role="alert">
        		No es un correo válido, debe tener el formato:  micuenta@dominio.com
      	</div>
    	);
  	}
};

const validPhone = (value) => {
  	if (value.length < 10 || value.length > 10) {
    	return (
      	<div className="alert alert-danger" role="alert">
        		El teléfono debe ser de 10 caractéres sin espacios ni carácteres especiales, ej: 4771230000.
      	</div>
    	);
  	}
};

const validatePassword = (value) => {
  	if (value.length < 6 || value.length > 40) {
    	return (
      	<div className="alert alert-danger" role="alert">
        	El password debe ser de más de 6 caractéres y menor a 40.
      	</div>
    	);
  	}
};


const Register = (props) => {
	const form = useRef();
  	const checkBtn = useRef();

  	const [name, setName] = useState("");
  	const [email, setEmail] = useState("");
  	const [phone, setPhone] = useState("");
  	const [password, setPassword] = useState("");
  	const [password_confirmation, setPasswordConfirmation] = useState("");
  	const [business, setBusiness] = useState("");
  	const [stps, setStps] = useState("");
  	const [successful, setSuccessful] = useState(false);
  	const [message, setMessage] = useState("");
  	const [loading, setLoading] = useState(false);

  	const onChangeName = (e) => {
		const name = e.target.value;
		setName(name);
	};

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePhone = (e) => {
		const phone = e.target.value;
		setPhone(phone);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const onChangePasswordConfirmation = (e) => {
		const password_confirmation = e.target.value;
		setPasswordConfirmation(password_confirmation);
	};

	const onChangeAccount = (e) => {
		setBusiness("");
		setStps("");
	};

	const onChangeBusiness = (e) => {
		const business = e.target.value;
		setBusiness(business);
	};

	const onChangeStps = (e) => {
		const stps = e.target.value;
		setStps(stps);
	};

	const [account, setAccount] = useState(1);

	let navigate = useNavigate();
	const handleRegister = (e) => {
		e.preventDefault();

		setMessage("");
		setSuccessful(false);
		setLoading(true);

		form.current.validateAll();

		if(checkBtn.current.context._errors.length === 0){
			AuthService.register(name, email, phone, password, password_confirmation, account, business, stps)
			.then(
				(response) => {
					setMessage("Registro de cuenta exitoso.");
					setSuccessful(true);
				},
				(error) => {
					const resMessage = ( error.response && error.response.data && error.response.data.message)
										|| error.message || error.toString();

					setMessage(resMessage);
					setSuccessful(false);
				}
			);
		}else{
			setLoading(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-100 flex items-center justify-center bg-cover" style={{ backgroundImage: "url('/img/banners/back-bomberos2.jpg')" }}>
			<div className="max-w-xl w-full flex flex-row bg-gray-100 rounded shadow-lg">
				<div className="register mx-4 w-1/2">
					<div className="mb-2 flex flex-col justify-center text-center">
						<h2 className="text-lg mt-2">Registrar Cuenta</h2>
					</div>
					{ !successful ?(
				   	<div className="bg-white rounded mt-2 p-4">
				   		<p>Elige tipo de cuenta:</p>
				   		<div className="flex justify-left mb-2">
							  	<div>
							    	<div className="form-check">
							      		<input type="radio" name="account" id="radio1" 
							      				onClick={() => setAccount(1)} onChange={onChangeAccount} defaultChecked className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
							      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio1">
							        		Individual
							      		</label>
							    	</div>
							    	<div className="form-check">
							    		<input type="radio" name="account" id="radio2" 
							    				onClick={() => setAccount(2)} onChange={onChangeAccount} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
							      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio2">
							        		Grupal
							      		</label>
							    	</div>
							    	<div className="form-check">
							      		<input type="radio" name="account" id="radio3" 
							      				onClick={() => setAccount(3)} onChange={onChangeAccount} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
							      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio3">
							        		Consultor Externo
							      		</label>
							    	</div>
							  	</div>
							</div>
							{account == 1 && (
							<p className="text-gray-500 text-sm">Cuenta para público en general. Puedes inscribirte en cualquier tipo de curso.</p>
							)}
							{account == 2 && (
				   			<p className="text-gray-500 text-sm">Cuenta para empresas, grupos colectivos, asociaciones civiles, instituciones, etc.</p>
				   		)}
				   		{account == 3 && (
				   			<p className="text-gray-500 text-sm">Cuenta para consultores con cédula de Protección Civil Estatal que desean rentar las instalaciones.</p>
				   		)}
				   	</div>
				   	):(
				   		<div className="">
				   			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
							</svg>
				   		</div>
				   	)}
			   </div>
			   <div className="w-1/2 login shadow p-4 bg-white rounded-l">
					<Form onSubmit={handleRegister} ref={form}>
						<div className="flex flex-col">
							{!successful && (
							<div>
								<div className="mb-2">
									<label className="block text-gray-700 text-sm mb-1">Nombre</label>
									<Input type="text" name="name" 
											className="px-4 py-1 rounded-full border border-gray-700 w-full"
											value={name} 									
											onChange={onChangeName}
											validations={[required, validateName]} />
								</div>
								<div className="mb-2">
									<label className="block text-gray-700 text-sm mb-1">Email</label>
									<Input type="email"name="email"
											className="px-4 py-1 rounded-full border border-gray-700 w-full"
											value={email}
											onChange={onChangeEmail}
											validations={[required, validEmail]} />
								</div>
								<div className="mb-2">
									<label className="block text-gray-700 text-sm mb-1">Teléfono</label>
									<Input type="text"name="phone"
											className="px-4 py-1 rounded-full border border-gray-700 w-full"
											value={phone}
											onChange={onChangePhone}
											validations={[required, validPhone]} />
								</div>
								<div className="mb-2">
									<label className="block text-gray-700 text-sm mb-1">Password</label>
									<Input type="password" name="password"
											className="px-4 py-1 rounded-full border border-gray-700 w-full"
											value={password}
											onChange={onChangePassword}
											validations={[required, validatePassword]} />
								</div>
								<div className="mb-2">
									<label className="block text-gray-700 text-sm mb-1">Confirmar Password</label>
									<Input type="password" name="password_confirmation"
											className="px-4 py-1 rounded-full border border-gray-700 w-full"
											value={password_confirmation}
											onChange={onChangePasswordConfirmation}
											validations={[required, validatePassword]} />
								</div>
								{account == 2 && (
						   			<div className="mb-2 border-dashed border border-gray-300 rounded bg-gray-50">
										<label className="block text-gray-700 text-sm mb-1 mx-2">Grupo</label>
										<Input 	type="text" name="business"
												className="px-4 py-1 rounded-full border border-gray-700 mx-2 mb-1"
												placeholder="Colectivo, Empresa, AC, etc."
												value={business}
												onChange={onChangeBusiness}
												validations={[required]} />
									</div>								
						   		)}
						   		{account == 3 && (
						   			<div className="mb-2 border-dashed border border-gray-300 rounded bg-gray-50">
										<label className="block text-gray-700 text-sm mb-1 mx-2">Registro Protección Civil Estatal</label>
										<Input 	type="text" name="stps"
												className="px-4 py-1 rounded-full border border-gray-700 mx-2 mb-1"												
												value={stps}
												onChange={onChangeStps}
												validations={[required]} />
									</div>
						   		)}
						   		<div className="my-2">
			             			<button className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline" type="submit">			             				
			             				{loading && (
			             				<div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
					           				)}
					           			<span>Registrar</span>
					           		</button>
			           			</div>

			           			<div className="flex mt-1 justify-center text-sm">
									<Link to="/login" className="text-blue-600 hover:text-blue-800 hover:underline">Ya tengo cuenta</Link>
								</div>
							</div>
							)}

							{message && (
							<div className="mb-2">
								<div className={ successful ? "text-green-500 text-xs italic" : "text-red-500 text-xs italic" } role="alert">
									{ message }
								</div>
							</div>
							)}

							{successful && (
							<div className="mb-2">
								<Link to="/login" className="text-blue-600 hover:text-blue-800 hover:underline">Ya puedes iniciar sesión aquí</Link>
							</div>	
							)}							

							<CheckButton style={{ display: "none" }} ref={checkBtn} />
						</div>
					</Form>
				</div>				
			</div>
		</div>
	);
};

export default Register;