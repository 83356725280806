import React, { useState, useEffect } from 'react';               // { useState, useEffect }
import { Routes, Route, Link } from 'react-router-dom';
import Welcome from "./components/Welcome";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Home from "./components/Entrance/Home";
import Schedule from "./components/Entrance/Schedule";
import Confirmation from "./components/Entrance/Confirmation";
import Done from "./components/Entrance/Done";
import ValidaQr from "./components/Entrance/ValidaQr";
import Dashboard from "./components/Dashboard/Dashboard";
import Archived from "./components/Dashboard/Archived";
import BoardIndividuals from "./components/Dashboard/BoardIndividuals";
import BoardGroups from "./components/Dashboard/BoardGroups";
import BoardConsultants from "./components/Dashboard/BoardConsultants";
import Course from "./components/Dashboard/Course";
import Certificate from "./components/Dashboard/Certificate";
import Ticket from "./components/Dashboard/Ticket";
import Profile from "./components/Auth/Profile";
import AuthService from "./services/auth.service";
import AuthVerify from "./common/AuthVerify";

const App = () => {
   
   const logOut = () => {
      AuthService.logout();
   };

   return (
      <div className=" mx-auto min-h-screen">                  
            <Routes>
               <Route path="/" element={<Welcome />} />
               <Route path="login" element={<Login />} />
               <Route path="register" element={<Register />} />
               <Route path="home" element={<Home />} />
               <Route path="schedule" element={<Schedule />} />
               <Route path="confirmation" element={<Confirmation />} />
               <Route path="done" element={<Done />} />
               <Route path="dashboard" element={<Dashboard />} />
               <Route path="archived" element={<Archived />} />
               <Route path="individuals" element={<BoardIndividuals />} />
               <Route path="groups" element={<BoardGroups />} />
               <Route path="consultants" element={<BoardConsultants />} />
               <Route path="course" element={<Course />} />
               <Route path="certificate" element={<Certificate />} />
               <Route path="ticket" element={<Ticket />} />
               <Route path="profile" element={<Profile />} />
               <Route path="validaQR/:code" element={<ValidaQr />} />
           </Routes>

           <AuthVerify logOut={logOut}/>           
      </div>
   );
};

export default App;