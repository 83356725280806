import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Header } from './Common/Header';

class Welcome extends Component {
	render() {
		return(
			<div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">			
				<div className="flex items-start w-full">
					<div className="w-2/5">
						<Header />
						<div className="flex items-center min-h-full">
							<div className="py-2 mt-32">
								<h1 className="text-6xl text-gray-300 uppercase font-black">Bienvenidos</h1>
								<h2 className="text-4xl uppercase font-black">Portal de Cursos</h2>
								<span className="text-lg">de Bomberos de León</span>
								<p className="mt-4 mb-8">Plataforma de cursos en las instalaciones de Bomberos de León</p>						
						      	<Link to="home" className="border border-red-700 rounded-full text-red-600 font-bold py-2 px-4 hover:bg-red-700 hover:text-white">Explora cursos disponibles →</Link>
					   	</div>
					   </div>
			      </div>
			      <div className="relative w-3/5 min-h-screen bg-cover" style={{ backgroundImage: "url('/img/banners/cursos-bomberos.jpg')" }}></div>
			   </div>

			</div>
		)
	}
}

export default Welcome;