import React, { useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import Navigator from "../Common/Navigator";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

const Dashboard = () => {
	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);
	const [courses, setCourses] = useState([{ name: "Cargando ...", id: "" }]);
	const [messageCall, setMessageCall] = useState("");
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();	
	useEffect(() => {
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, []);

   useEffect(() => {	
		UserService.getCoursesByUserArchived(user.user_id).then(
			(response) => {
				setCourses([{ name: "Cargando ...", id: "" }]);
				setCourses(response.data.registers.map(({ id, course_name, date_requested, hour_requested, for_date, for_hour }) => ({ id: id, name: course_name, date_requested: formatDate(date_requested), hour_requested: hour_requested, for_date: formatDate(for_date), for_hour: for_hour })));
				if(response.data.registers.length == 0){
					setMessageCall("No se encontró información de cursos registrados.");
				}
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);
			}
		);
	}, []);

	function formatDate(dt){
		//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		//var datestring = new Date(dt).toLocaleDateString("es-MX", options);
		if(dt){
			var d = new Date(dt.replace(/-/g, '\/').replace(/T.+/, ''));	//var d = new Date(dt);
			var datestring = d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
			return datestring;
		}
	};

	return (				
		<div className="w-full mx-auto overflow-y-hidden">
			<Navigator />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8 my-6">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }

				<h2 className="text-2xl text-red-600 font-bold uppercase">Elige una modalidad de cursos</h2>									
				<div className="flex gap-4 my-10">
					<div className="flex-1">
						<Link to="/individuals">
							<div className="max-w-sm mx-auto flex p-6 bg-white rounded-lg shadow-lg hover:shadow-xl">
							  	<div className="flex-shrink-0">
							   	<svg xmlns="http://www.w3.org/2000/svg" className="h-10 text-blue-500" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
	                        	<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path>
	                      	</svg>
							  </div>
							  <div className="ml-6 pt-1">
							    <h4 className="text-xl text-gray-900 leading-tight">Individuales</h4>
							    <p className="text-sm text-gray-600 leading-normal">Cursos individuales para público en general</p>
							  </div>
							</div>
						</Link>
					</div>
					<div className="flex-1">
						<Link to="/groups">
							<div className="max-w-sm mx-auto flex p-6 bg-white rounded-lg shadow-lg hover:shadow-xl">
							  	<div className="flex-shrink-0">
							   	<svg xmlns="http://www.w3.org/2000/svg" className="h-10 text-orange-500" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
	                          <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>
	                        </svg>
							  </div>
							  <div className="ml-6 pt-1">
							    <h4 className="text-xl text-gray-900 leading-tight">Grupales</h4>
							    <p className="text-sm text-gray-600 leading-normal">Cursos para empresas, asociaciones, etc</p>
							  </div>
							</div>
						</Link>
					</div>
					<div className="flex-1">
						<Link to="/consultants">
							<div className="max-w-sm mx-auto flex p-6 bg-white rounded-lg shadow-lg hover:shadow-xl">
							  	<div className="flex-shrink-0">
							   	<svg xmlns="http://www.w3.org/2000/svg" className="h-10 text-green-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                           	<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"></path>
                        	</svg>
							  </div>
							  <div className="ml-6 pt-1">
							    <h4 className="text-xl text-gray-900 leading-tight">Renta de Campo</h4>
							    <p className="text-sm text-gray-600 leading-normal">Cursos impartidos por consultores</p>
							  </div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;