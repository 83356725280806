import React, { useState, useEffect, useRef} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Navigator from "../Common/Navigator";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import UploadService from "../../services/file.service";
import './Course.css';

const required = (value) => {
	if(!value){
		return (
			<div className="alert alert-danger" role="alert">
				Este campo es requerido!
			</div>
		);
	}
};

const Course = () => {
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();	
	useEffect(() => {
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
			return;
		};
	}, []);

	const location = useLocation();
  	const { register_id } = location.state;
  	const [message, setMessage] = useState("");
  	const [typeMessage, setTypeMessage] = useState("danger");
	const [showmsg, setShowmsg] = useState(false);	
	const [course, setCourse] = useState([{ id: "", name: "Cargando ...", content: "", date_requested: "", hour_requested: "", paid: "", modality_id: 0, qty: 1, in_training: 0 }]);
	const [people, setPeople] = useState([]);
	const [datereq, setDatereq] = useState("");
	const [datereal, setDatereal] = useState("");
	const [hourreal, setHourreal] = useState("");
	const [diff, setDiff] = useState(0);
	const [nopeople, setNopeople] = useState([]);
	const [check_paid, setCheckPaid] = useState("");
	const [check_finished, setCheckFinished] = useState("");
	const [check_attended, setCheckAttended] = useState("");
	const [message_discard, setMessageDiscard] = useState("");
	const [selected, setSelected] = useState('yes');
	useEffect(() => {
		UserService.getDataFromCourse(register_id).then(
			(response) => {
            	let cdata = response.data.course;            	
            	setCourse({ id: cdata.id, name: cdata.name, content: cdata.content, date_requested: cdata.date_requested,  hour_requested: cdata.hour_requested, for_date: cdata.for_date, for_hour: cdata.for_hour, paid: cdata.paid, modality_id: cdata.modality_id, qty: cdata.qty, in_training: cdata.in_training, archived: cdata.archived, ticket: cdata.ticket, constancy: cdata.constancy });
            	var cp = (cdata.paid == 1) ? 'checked' :'';
            	setCheckPaid(cp);
            	var cd = (cdata.done == 1) ? 'checked' :'';
            	setCheckFinished(cd);
            	var ca = (cdata.attended == 1) ? 'checked' :'';
            	setCheckAttended(ca);
            	setDatereq(formatDate(cdata.date_requested));            	
            	if(cdata.for_date){
            		setDatereal(formatDate(cdata.for_date));
            	}
            	if(cdata.for_hour){
            		setHourreal(cdata.for_hour);
            	}            	
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);
			}
		);

	}, []);
	
	useEffect(() => {
		if(course.modality_id > 0){
			UserService.getPeopleFromRegister(register_id, course.modality_id, user.user_id).then(
				(response) => {
	            	setPeople([]);
	            	setPeople(response.data.people.map(({ id, indx, name, business_name, attended }) => ({ id: id, indx: indx, name: name, business: business_name, attended: attended })));
	            	if(course.qty > response.data.people.length){	            	
	            		let diff = parseInt(course.qty) - parseInt(response.data.people.length);	            		
	            		var drows = [];
	            		for (var i=0; i < diff; i++) {	            			
	            			drows.push({id: i, name: ""});
	            		}	            		
	            		setNopeople(drows);
	            	}
				},
				(error) => {
					const _content = (error.response && error.response.data) 
											|| error.message || error.toString();
					setMessage(_content);
					setShowmsg(true);
				}
			);
		}

	}, [course]);

	const form = useRef();
	const checkBtn = useRef();
  	const formArch = useRef();
  	const checkBtnArch = useRef();
	const formDisc = useRef();
	const checkBtnDisc = useRef();
  	const [formpeople, setFormpeople] = useState([]);
	const [messageList, setMessageList] = useState("");

	const handlePeople = (e) => {
		e.preventDefault();
		form.current.validateAll();
		if(checkBtn.current.context._errors.length === 0){
			//var arr = $('.np').map((i, e) => e.value).get();	
			var arrIndex = [];
			var indexes = document.getElementsByClassName("ip");			
			for (var i = 0, len = indexes.length; i < len; i++) {			    
			    arrIndex.push(indexes[i].value);			    
			}
			var arrPeople = [];
			var persons = document.getElementsByClassName("np");			
			for (var i = 0, len = persons.length; i < len; i++) {			    
			    arrPeople.push(persons[i].value);			    
			}
			var arrBisne = [];
			var bisnes = document.getElementsByClassName("bp");
			for (var i = 0, len = bisnes.length; i < len; i++) {			    
			    arrBisne.push(bisnes[i].value);			    
			}			
			UserService.savePeopleToCourse(register_id, arrIndex, arrPeople, arrBisne, course.in_training)
				.then(
					(response) => {					
						setMessageList("Registro de participantes exitoso.");						
					},
					(error) => {
						const resMessage = ( error.response && error.response.data && error.response.data.message)
											|| error.message || error.toString();
						setMessageList(resMessage);						
					}
				);				
		}else{
			setMessageList("Error al guardar participantes.");			
		}	    
	};

	const handleArchivate = (e) => {
		e.preventDefault();
		formArch.current.validateAll();
		if(checkBtnArch.current.context._errors.length === 0){
			UserService.saveArchivateRegister(register_id)
				.then(
					(response) => {		
						setTypeMessage("success");
						setMessage("Curso Archivado");
						setShowmsg(true);					
					},
					(error) => {
						const resMessage = ( error.response && error.response.data && error.response.data.message)
											|| error.message || error.toString();
						setMessage(resMessage);						
						setShowmsg(true);			
					}
				);				
		}else{
			setMessageList("Error al archivar.");			
		}	    
	};

	const [selectedFiles, setSelectedFiles] = useState(undefined);
	const [selectedStps, setSelectedStps] = useState(undefined);
	const [currentFile, setCurrentFile] = useState(undefined);
	const [progress, setProgress] = useState(0);	
	const [messageFile, setMessageFile] = useState("");
	const [messageStps, setMessageStps] = useState("");
	const [showSpinC, setShowSpinC] = useState(false);
	const [showSpinT, setShowSpinT] = useState(false);
	const selectFile = (event) => {
		setSelectedFiles(event.target.files);
	};
	const selectStps = (event) => {
		setSelectedStps(event.target.files);
	};
	const upload = () => {
	    let currentFile = selectedFiles[0];
	    let typ = "fil";
	    setProgress(0);
	    setCurrentFile(currentFile);
	    setShowSpinT(true);
	    UploadService.upload(register_id, currentFile, typ, (event) => {
	      setProgress(Math.round((100 * event.loaded) / event.total));
	    })
	      .then((response) => {
	        setMessageFile(response.data.message);	//return UploadService.getFiles();
	        setShowSpinT(false);
	        window.location.reload();
	      })
	      .then((files) => {	//setFileInfos(files.data);
	      })
	      .catch(() => {
	        	setProgress(0);
	        	setMessage("No se pudo subir el archivo.");
				setShowmsg(true);	        	
	        	setCurrentFile(undefined);	        	
	        	setShowSpinT(false);
	      });
	    setSelectedFiles(undefined);
	};

	const uploadStps = () => {
	    let currentFile = selectedStps[0];
	    setProgress(0);
	    let typ = "stps";
	    setCurrentFile(currentFile);
	    setShowSpinC(true);
	    UploadService.upload(register_id, currentFile, typ, (event) => {
	      setProgress(Math.round((100 * event.loaded) / event.total));
	    })
	      .then((response) => {
	        setMessageStps(response.data.message);	//return UploadService.getFiles();
	        setShowSpinC(false);
	        window.location.reload();
	      })
	      .then((files) => {	//setFileInfos(files.data);
	      })
	      .catch(() => {
	        	setProgress(0);
	        	setMessage("No se pudo subir el archivo.");
				setShowmsg(true);	        	
	        	setCurrentFile(undefined);
	        	setShowSpinC(false);
	      });
	    setSelectedFiles(undefined);
	};

	const handleDiscard = (e) => {
		e.preventDefault();
		const answer = window.confirm("¿Seguro deseas descartarlo?");
	   if (answer) {
	   	formDisc.current.validateAll();		
			if(checkBtnDisc.current.context._errors.length === 0){
				UserService.saveDiscardRegister(register_id)
				.then(
					(response) => {
						setTypeMessage("success");
						setMessage("Registro al curso descartado");
						setShowmsg(true);	
					},
					(error) => {
						const resMessage = ( error.response && error.response.data && error.response.data.message)
											|| error.message || error.toString();
						setMessage(resMessage);
						setShowmsg(true);	
					}
				);
			}else{
				setMessageList("Error al descartar curso.");			
			}

	   } else {
	   	// noothing happens
	   }		
	};

	function formatDate(dt){
		//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		//var datestring = new Date(dt).toLocaleDateString("es-MX", options);		
		var d = new Date(dt.replace(/-/g, '\/').replace(/T.+/, ''));	//var d = new Date(dt);
		var datestring = d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
		return datestring;
	};

	return (		
		<div className="w-full min-h-screen mx-auto">
			<Navigator />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
				{ showmsg && <Message message={message} type={typeMessage} show={showmsg} /> }
				
				<div className="flex">
					<div className="w-2/4">
						<h2 className="text-2xl font-bold my-4 uppercase text-red-500" title={course.id}>{ course.name }</h2>
					</div>
					<div className="w-1/4 text-center">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
						</svg>
						<h2 className="mt-2">{datereal}</h2>
					</div>
					<div className="w-1/4 text-center">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
						<h2 className="mt-2">{hourreal}</h2>
					</div>
				</div>

				<div className="flex border-t mt-2">					
					<div className="border-red-500 h-6 w-4/5">Fecha solicitada: {datereq} {course.hour_requested}</div>
					<div className="bg-red-500 h-6 w-1/5"></div>
				</div>

				<div className="flex flex-col">
					<h3 className="mt-2 font-semibold my-2">Contenido:</h3>
					<div className="border rounded bg-gray-100 h-24 overflow-y-auto px-8 py-2">
						<p>{course.content}</p>
					</div>
				</div>
				<div className="flex items-center flex-row-reverse gap-4">
					<div className="">
						<Link to="/ticket" state={{ register_id: register_id, course_name: course.name, course_id: course.id }} className="block mt-2 hover:underline">
							<h2 className="font-bold text-lg text-blue-600 underline hover:text-blue-800">Recibo de pago</h2>
						</Link>
					</div>
					{ (course.archived == 0 && check_finished) && (					
					<div className="">
						<Form onSubmit={handleArchivate} ref={formArch}>							
							<button className="font-bold text-base bg-indigo-500 hover:bg-indigo-700 text-white px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Archivar</button>
							<CheckButton style={{ display: "none" }} ref={checkBtnArch} />
						</Form>
					</div>
					)}
					{ (course.archived == 0) && (
					<Form onSubmit={handleDiscard} ref={formDisc}>
						<div className="flex flex-col">
							<div>							
								<Input type="hidden" name="register_id" 
											className="px-4 py-1 rounded-full border border-gray-700"
											value={register_id}
											validations={[required]} />
								<div className="my-4">
			             		<button className="font-bold text-base bg-red-500 hover:bg-red-700 text-white px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Descartar</button>
			          		</div>
							</div>
							<CheckButton style={{ display: "none" }} ref={checkBtnDisc} />
						</div>
					</Form>
					)}
				</div>

				<div className="flex gap-4 mt-2">
					<div className="w-3/5 pr-12">						
						{course.modality_id > 0 && (
							<div>
								<h3 className="mt-2 font-semibold my-2">Lista de Participantes:</h3>
								<div className="overflow-y-auto px-1">									
									<Form onSubmit={handlePeople} ref={form}>
										<div>
										{people.map((item, index) => (
											<div  key={index}>
												<div className="flex items-center gap-4">
													<input type="hidden" name="indexes[]" defaultValue={item.indx} className="ip" />
													<div className="flex-grow">
														<div className="flex w-full gap-2">
															<div className="w-3/5">
																<input type="text" name="people[]" placeholder="Nombre" defaultValue={item.name} className="np w-full my-1 border-b border-gray-400"/>
															</div>
															<div className="w-2/5">
																<input type="text" name="business[]" placeholder="Grupo, empresa, colectivo..." defaultValue={item.business} className="bp w-full my-1 border-b border-gray-400"/>
															</div>
														</div>
													</div>
													<div className="flex-none w-12">
													{(item.attended == 1 && check_finished) &&(
														<Link to="/certificate" state={{ tp_id: item.id }} className="block mt-2 hover:underline">
															<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															  	<path d="M12 14l9-5-9-5-9 5 9 5z" />
															  	<path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
																<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
															</svg>
														</Link>
													)}
													</div>
												</div>
											</div>
									   ))}
										</div>
									   {nopeople.map((item, index) => (
										<div className="flex items-center gap-4" key={index}>
											<div className="flex-grow">
												<input type="hidden" name="indexes[]" defaultValue={index+1} className="ip" />
												<div className="flex w-full gap-2">
													<div className="w-3/5">
														<input type="text" name="people[]" placeholder="Nombre"  className="np w-full my-1 border-b border-gray-400" />
													</div>
													<div className="w-2/5">
														<input type="text" name="business[]" placeholder="Grupo, empresa, colectivo..." className="bp w-full my-1 border-b border-gray-400" />
													</div>
												</div>
											</div>
										</div>
									    ))}
									    {(course.in_training > 0 && !check_finished) &&(
									    	<div className="mt-2">									    	
												<button className="bg-green-500 hover:bg-green-700 text-white font-bold px-4 rounded-full focus:outline-none focus:shadow-outline" type="submit">Guardar</button>
												<CheckButton style={{ display: "none" }} ref={checkBtn} />
											</div>
									    )}
									</Form>
								</div>
								<p className="bg-green-100 text-green-600 my-2">{ messageList }</p>
							</div>
						)}
					</div>
					<div className="w-2/5">
						<div className="flex items-center">
							<h3 className="flex-1 mt-2 font-semibold my-2">Estatus:</h3>							
							<div className="flex-1">
								<label className="md:w-2/3 block text-gray-500 font-bold flex items-center">
									{check_paid ?
										<input className="mr-2 leading-tight inline-block accent-green-300 scale-150" type="radio" value="yes" checked={selected === 'yes'} readOnly /> :<input className="mr-2 leading-tight inline-block scale-150" type="radio" readOnly/> 
									}
									<span className="text-sm inline-block">
							        	Pagado 
							      </span>
						    	</label>
						    </div>
						    <div className="flex-1">
								<label className="md:w-2/3 block text-gray-500 font-bold flex items-center">
									{check_finished ?
										<input className="mr-2 leading-tight inline-block accent-green-300 scale-150" type="radio" value="yes" checked={selected === 'yes'} readOnly /> :<input className="mr-2 leading-tight inline-block scale-150" type="radio" readOnly/> 
									}
									<span className="text-sm inline-block">
							        	Concluido 
							      </span>
						    	</label>
						    </div>
						</div>
						<div className="flex flex-col mt-4">
							<div className="box-file h-32">
								{check_paid ?
								<div></div>
								:
								<div className="my-2">
									<h3 className="mt-2 font-semibold">Comprobante de pago:</h3>
									{course.ticket &&
							         <p className="text-blue-500">{course.ticket.substring(course.ticket.lastIndexOf("/") + 1)}</p>
							      }
								   <input type="file" onChange={selectFile} className="block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
								   <button className="bg-green-500 rounded-full text-white px-4 inline-block mt-2 self-" disabled={!selectedFiles} onClick={upload}>
								   	{course.ticket ?
								   		<span>Volver a subir</span>
								   		:
								   		<span>Subir</span>
								   	}
								   </button>
								   {showSpinT &&
								   	<div id="spinner"></div>
									}
								   <p className="bg-green-100 text-green-600 my-2">{ messageFile }</p>
								</div>								
								}
								{course.modality_id > 0 &&(
								<div className="my-2">
									<h3 className="mt-2 font-semibold">Constancia de Situación Fiscal:</h3>
									{course.constancy &&
							         <p className="text-blue-500">{course.constancy.substring(course.constancy.lastIndexOf("/") + 1)}</p>
							      }
								   <input type="file" onChange={selectStps} className="block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
								   <button className="bg-green-500 rounded-full text-white px-4 inline-block mt-2 self-" disabled={!selectedStps} onClick={uploadStps}>
								   	{course.constancy ?
								   		<span>Volver a subir</span>
								   		:
								   		<span>Subir</span>
								   	}
								   </button>								   
								   {showSpinC &&
								   	<div id="spinner"></div>
									}
								   <p className="bg-green-100 text-green-600 my-2">{ messageStps }</p>
								</div>
								)}
						   </div>
						</div>
					</div>					
				</div>

			</div>
		</div>
	);
};

export default Course;