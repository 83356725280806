import React, { useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Top } from "../Common/Header";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

function Home ()  {		
	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);
	const [expirated, setExpirated] = useState(false);
	const [courses, setCourses] = useState([]);
	const [course_name_selected, setCourseNameSelected] = useState("");
	const [course_id_selected, setCourseIdSelected] = useState("");
	const [course_content_selected, setCourseContentSelected] = useState("");
	const [course_cost_selected, setCourseCostSelected] = useState("");
	const [course_typepay_selected, setCourseTypepaySelected] = useState("");
	const [course_just_home_selected, setCourseJustHomeSelected] = useState(0);
	const [group_name, setGroupName] = useState("");
	const [role, setRole] = useState(undefined);
	const [qty, setQty] = useState(1);
	const [hours, setHours] = useState(1);
	const [preubication, setPreubication] = useState("");
	const [ubication, setUbication] = useState("CCB");
	const [enable_ubication, setEnableUbication] = useState(false);
	const [loading, setLoading] = useState(true);
	const [disabled_next, setDisabledNext] = useState(false);	
	let navigate = useNavigate();
	
	const [account, setAccount] = useState(1);
	const onChangeAccount = (e) => {
		setCourseNameSelected("");
		setCourseContentSelected("");
		setCourseCostSelected("");
		let rol_clicked = e.target.value;
		getCourses(rol_clicked);
		if(rol_clicked>1){ setQty(20); }else{ setQty(1); }
		setDisabledNext(false);		
	};

	function resetFields(){
		setQty(1);
		setHours(1);
		setPreubication("");
		setUbication("CCB");
		setGroupName("");
	}

	const onChangeSelected = (e) => {
		let course_name_selected_t = e.target[e.target.selectedIndex].text;
		setCourseNameSelected(course_name_selected_t);
		let course_id_selected = e.target[e.target.selectedIndex].value;
		setCourseIdSelected(course_id_selected);
		let course_content = e.target[e.target.selectedIndex].getAttribute("content");
		setCourseContentSelected(course_content);
		let course_cost = e.target[e.target.selectedIndex].getAttribute("cost");
		setCourseCostSelected(course_cost);
		let course_typepay = e.target[e.target.selectedIndex].getAttribute("typepay");
		setCourseTypepaySelected(course_typepay);
		let course_just_home = e.target[e.target.selectedIndex].getAttribute("justhome");
		setCourseJustHomeSelected(course_just_home);
		//setDisabledNext(false);
		changeStatus();
		/*if(course_name_selected.length > 2){
			setDisabledNext(true);
		}else{
			setDisabledNext(false);
		}*/
	};	

	const onClickOption = (e) => {
		let name = e.target[e.target.selectedIndex].text;		
		changeStatus();
	};

	const onChangeGroupName = (e) => {
		let name = e.target.value;		
		setGroupName(name);
		changeStatus();
	};	

	const onChangeQty = (e) => {
		let qty = e.target.value;
		if(qty > 20){ qty = 20; }
		setQty(qty);
		changeStatus();
	};

	const onChangeHours = (e) => {
		let hours = e.target.value;		
		setHours(hours);
		changeStatus();
	};

	const onChangePreubication = (e) => {
		let preubication = e.target.value;		
		setPreubication(preubication);
		if(preubication=="Otro"){
			setEnableUbication(true);
			setUbication("");
		}else{
			setEnableUbication(false);
			setUbication("CCB");
		}
		changeStatus();
	};

	const onChangeUbication = (e) => {
		let ubication = e.target.value;		
		setUbication(ubication);		
		changeStatus();
	};

	function changeStatus(){
		console.log("changeStatus "+account);
		if(account == 1){
			console.log("acc = 1, course: " + course_name_selected)
			if(course_name_selected.length > 2){				
				console.log("course name ("+course_name_selected+") > 2")
				setDisabledNext(true);
			}else{
				setDisabledNext(false);
			}			
		}
		if(account == 2){
			if(course_name_selected.length > 2 && qty >= 1 && group_name.length > 1){
				setDisabledNext(true);
			}else{
				setDisabledNext(false);
			}			
		}
		if(account == 3){
			if(course_name_selected.length > 2 && qty >= 1 && hours >= 1){
				setDisabledNext(true);
			}else{
				setDisabledNext(false);
			}			
		}
	};

	function getCourses(rol_clicked){
		UserService.getHomeContent(rol_clicked).then(
			(response) => {				
				setCourses(response.data.courses.map(({ id, name, content, cost, typepay, just_home }) => ({ id: id, name: name, content: content, cost: cost, typepay: typepay, just_home: just_home })));
				setLoading(false);
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);

				/*				
				if (error.response && error.response.status === 401) {
		         	AuthSersvice.logout();		         	
		        }
		        */
			}
		);
	}

	useEffect(() => {
		const user = AuthService.getCurrentUser();			
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		}
		let exp_date = user.expiration;
		if(exp_date){
			/*var frmt = new Intl.DateTimeFormat("es-MX", {
				timeZone: "America/Mexico_City",
				timeZoneName: "short"
			});*/
			let d1 = new Date();
			//let d1 = frmt.format(d1n);
			let d2 = new Date(exp_date.replace(/-/g, '\/'));
			//let d2 = frmt.format(d2n);

			console.log("d1:"+d1);
		  	console.log("d2:"+d2);		  	
		  	
		  	if (d2 < d1) {
		   	setExpirated(true);	console.log(d2+" is less than "+d1);
		  	}else if (d2 > d1) {
		   	setExpirated(false);	console.log(d2+" is greater than "+d1);
		  	} else {
		    	//console.log('Both dates are equal');
		  	}
		}
		setRole(user.role);
		
		UserService.getHomeContent(user.role).then(
			(response) => {				
				//setCourses(response.data.courses.map(({ id, name, content, cost, typepay }) => ({ id: id, name: name, content: content, cost: cost, typepay: typepay })));
				setLoading(false);
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);

				/*				
				if (error.response && error.response.status === 401) {
		         	AuthSersvice.logout();		         	
		        }
		        */
			}
		);
	}, [navigate]);

	return (
		<div className="w-full min-h-screen mx-auto overflow-y-hidden pb-8">
			<Top />					

			<div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8 my-6">			
				<div  className="flex items-center border px-4 py-2 gap-2 bg-gray-50 rounded">
					<div className="w-full ">
						<hr className="border-t-4 border-gray-300"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 1</h5>
						<p className="">Datos del registro</p>
					</div>
					<div className="w-full">
						<hr className="border-t-4"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 2</h5>
						<p className="">Agendar fecha</p>
					</div>
					<div className="w-full">
						<hr className="border-t-4"/>
						<h5 className="tracking-widest uppercase text-xs text-red-500 font-bold">PASO 2</h5>
						<p className="">Resumen del registro</p>
					</div>
				</div>
			</div>

			<div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8 mt-6">

				<div className="flex">
					<div className="w-1/2 pr-24">
						<div className="flex flex-col">
							{ showmsg && <Message message={message} type="danger" show={showmsg} /> }
							
							<div className="flex items-center">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block mr-4" viewBox="0 0 20 20" fill="currentColor">
								  <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
								</svg>
								<h2 className="text-base mb-2 font-bold uppercase">Selecciona el curso o renta de tu interés:</h2>
							</div>
							<div className="bg-white rounded mt-4">
					   		<p>Elige la modalidad de curso o renta de campo:</p>
					   		<div className="flex justify-left mb-2 gap-4 bg-gray-50 p-2 border-solid border border-red-300 shadow-md rounded">
								  	<div className="w-1/3">
								    	<div className="form-check">
								      		<input type="radio" name="account" id="radio1" 
								      				onClick={() => setAccount(1)} value="1" onChange={onChangeAccount} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
								      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio1">
								        		Individual
								      		</label>
								    	</div>
								    	<div className="form-check">
								    		<input type="radio" name="account" id="radio2" 
								    				onClick={() => setAccount(2)} value="2" onChange={onChangeAccount} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
								      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio2">
								        		Grupal
								      		</label>
								    	</div>
								    	{(!expirated && role == 3) &&(
								    	<div className="form-check">
								      		<input type="radio" name="account" id="radio3" 
								      				onClick={() => setAccount(3)} value="3" onChange={onChangeAccount} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
								      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio3">
								        		Renta
								      		</label>
								    	</div>
								    	)}
								    	{expirated &&(
								    	<div className="form-check">
								      		<input type="radio"readonly disabled className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
								      		<label className="cursor-pointer form-check-label inline-block text-gray-800" htmlFor="radio3">
								        		Renta
								      		</label>
								      		<p className="text-sm text-red-500">Constancia ST expirada</p>
								    	</div>
								    	)}
								  	</div>
								  	<div className="w-2/3">
								  		<div className="">
									  		{account == 1 && (
											<p className="text-gray-700 text-sm">Cursos para público en general. El curso se comparte con otras personas del mismo interés.</p>
											)}
											{account == 2 && (
								   			<p className="text-gray-700 text-sm">Cursos para empresas, grupos colectivos, asociaciones, etc., con una cantidad de personas definida.</p>
								   		)}
								   		{account == 3 && (
								   			<p className="text-gray-700 text-sm">Renta de instalaciones de CCB exclusivamente a consultores con cédula STPS que imparten cursos a sus clientes.</p>
								   		)}								   		
								   	</div>
								  	</div>								  	
								</div>								
					   	</div>
							<select name="course" disabled={loading} onClick={onClickOption}  onChange={onChangeSelected} className="px-4 py-1 rounded-full border bg-gray-100 border-gray-400">
								<option value="">--</option>
								{courses.map(item => (
						        <option key={item.id + item.name} value={item.id} cost={item.cost} content={item.content} typepay={item.typepay} justhome={item.just_home} >{item.name}</option>
						   	))}			      
						   </select>
						   {account > 1 && (
							<div className="mt-4">
								<div className="flex items-center">
									<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
									  <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								  	<h2 className="text-base mb-2 font-bold uppercase">Cantidad de participantes:</h2>
							   </div>
							   <input type="number" name="qty" value={qty} min="1" onChange={onChangeQty} className="ml-14 px-4 py-1 rounded-full border bg-gray-100 border-gray-400"/>
							</div>					
						   )}
						   {account == 2 && (
							<div className="mt-4">
								<div className="flex items-center">
									<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
									  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
									</svg>
								  	<h2 className="text-base mb-2 font-bold uppercase">Nombre de Grupo/Empresa:</h2>
							   </div>
							   <input type="text" name="group_name" value={group_name} minLength="3" onChange={onChangeGroupName} className="w-3/4 ml-14 px-4 py-1 rounded-full border bg-gray-100 border-gray-400"/>
							</div>					
						   )}
						   {account == 3 && (
							<div className="mt-4">
								<div className="flex items-center">									
									<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
									 	<path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
									</svg>
								   <h2 className="text-base mb-2 font-bold uppercase">Cantidad de horas:</h2>
							   </div>
							   <input type="number" name="hours" value={hours} min="1" max="4" onChange={onChangeHours} readOnly className="ml-14 px-8 py-1 rounded-full border bg-gray-100 border-gray-400"/>
							</div>					
						   )}	
							{account == 2 && (
						   <div className="mt-4">
								<div className="flex items-center">									
									<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block mr-4"  fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
									  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
									  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
									</svg>
								   <h2 className="text-base mb-2 font-bold uppercase">Ubicación:</h2>
							   </div>
							   <div className=" items-center">
							   	<div className="flex items-center gap-4">
								   	<input type="radio" name="preubication" value="CCB" onChange={onChangePreubication} id="ubication_ccb" /><label className="pl-0" htmlFor="ubication_ccb">Centro de Capacitación de Bomberos</label>
	        							{course_just_home_selected == 0 &&(
										<div>
											<input type="radio" name="preubication" value="Otro" onChange={onChangePreubication} id="ubication_other" /><label className="pl-0" htmlFor="ubication_other">Otro</label>
										</div>	        							
	        							)}
	        						</div>
							   	{enable_ubication && (	
							   	<input type="text" name="ubication" value={ubication} onChange={onChangeUbication} placeholder="Captura la ubicación deseada" className="ml-2 px-2 py-1 w-full rounded-full border bg-gray-100 border-gray-400"/>
							   	)}
							   </div>
							</div>
							)}
						</div>
					</div>
					<div className="w-1/2">
						<div>
							<h2 className="text-lg font-boldn text-red-500">{course_name_selected}</h2>
							<div className="h-48 overflow-y-auto border rounded bg-gray-50 my-2">
							{course_content_selected &&(
								<div className=" ">
								<p className="p-4">{course_content_selected}</p>
								</div>
							)}
							</div>
							{course_cost_selected &&(
								<p className="text-base text-red-500"><span className="text-black">Costo: </span>${course_cost_selected} {account === 3 &&( <span>/ hora</span> )}. <span className="text-gray-500">Incluye IVA.</span></p>
							)}							
						</div>
					</div>
				</div>
				<div className="flex justify-center mt-4">
				{disabled_next && (							
					<Link to="/schedule" disabled={loading} state={{ course_name: course_name_selected, course_id: course_id_selected, qty: qty, hours_ori: hours, cost: course_cost_selected, typepay: course_typepay_selected, modality_id: account, group_name: group_name, ubication: ubication}} className="block mt-4 border bg-red-500 hover:bg-red-900 border-red-700 rounded-full text-white font-bold py-2 px-4">Siguiente →</Link>
				)}
				</div>
			</div>
		</div>
	);
};

export default Home;