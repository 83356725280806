import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const getHomeContent = (role) => {
	return axios.get(API_URL + "/home/"+role, { headers: authHeader() });
};

const getHoursFromDay = (course_id, dt) => {
	return axios.get(API_URL + "/schedule/hours/"+course_id+"/"+dt, { headers: authHeader() });
};

const registerToCourse = (course_id, modality_id, user_id, date_requested, hour_requested, qty, hours, cost, group_name, ubication) => {
	return axios.post(API_URL + '/registers', 		
		{ course_id, modality_id, user_id, date_requested, hour_requested, qty, hours, cost, group_name, ubication },
		{ headers: authHeader() }
	);
};

const getCoursesByUser = (user_id, modality_id, datetime, cname) => {
	return axios.get(API_URL + "/registers/user/"+user_id+"/"+modality_id+"/"+datetime+"/"+cname, { headers: authHeader() });
};

const getCoursesByUserArchived = (user_id) => {
	return axios.get(API_URL + "/registers/archived/user/"+user_id, { headers: authHeader() });
};

const getDataFromCourse = (register_id) => {
	return axios.get(API_URL + "/registers/"+register_id, { headers: authHeader() });
};

const getPeopleFromRegister = (register_id, modality_id, user_id) => {
	return axios.get(API_URL + "/registers/people/"+register_id+"/"+modality_id+"/"+user_id, { headers: authHeader() });
};

const savePeopleToCourse = (register_id, indexes, people, business, in_training) => {
	return axios.post(API_URL + '/registers/people', 		
		{ register_id, indexes, people, business, in_training },
		{ headers: authHeader() }
	);
};

const saveArchivateRegister=  (register_id) => {
	return axios.post(API_URL + '/registers/archivate', 		
		{ register_id },
		{ headers: authHeader() }
	);
};

const getDataForCertificate = (tp_id) => {
	return axios.get(API_URL + "/certificate/"+tp_id, { headers: authHeader() });
};

const getDataCompany = () => {
	return axios.get(API_URL + "/company/bdl", { headers: authHeader() });
};

const saveDiscardRegister = (register_id) => {
	return axios.post(API_URL + '/registers/discard', 		
		{ register_id },
		{ headers: authHeader() }
	);
};

const checkQr = (code) => {
	return axios.post(API_URL + '/checkqr', {code} );
};
export default { getHomeContent, getHoursFromDay, registerToCourse, getCoursesByUser, getCoursesByUserArchived, getDataFromCourse, getPeopleFromRegister, savePeopleToCourse, saveArchivateRegister, saveDiscardRegister, getDataForCertificate, getDataCompany, checkQr };
