import React from 'react';
import { Link } from 'react-router-dom';
import AuthService from "../../services/auth.service";

const logOut = () => {
  AuthService.logout();
};

export const Header = () => {
  return (
    <header className="header">
      <div className="flex items-center p-1">
        <div className="text-left">
          <Link to="/">
            <img src="/img/bomberos-de-leon.png" alt="BDL" className="h-16" />
          </Link>
        </div>
        <div className="text-center ml-5">
          <h2 className="border-l border-gray-500 pl-5 text-sm text-gray-600">Centro de Capacitación de Bomberos</h2>
        </div>
      </div>
    </header>
  )
}

export const Top = () => {
  return (
    <div className="top bg-rose-600">
      <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex items-center p-1 text-white">
          <div className="text-left">
            <Link to="/">
              <img src="/img/bomberos-de-leon.png" alt="BDL" className="h-12" />
            </Link>
          </div>
          <div className="text-center ml-5">          
            <h2 className="pl-5 text-sm text-gray-100">Centro de Capacitación de Bomberos</h2>
          </div>
          <div className="ml-auto text-center ml-5 flex">
            <Link to="/dashboard" className="rounded-full border border-gray-50 px-4 py-1 hover:bg-white hover:text-red-500 flex items-center print:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
              </svg>
              Mi Cuenta
            </Link>
            <Link to="/dashboard" onClick={logOut} className="rounded-full border border-gray-50 px-4 py-1 ml-2 hover:bg-white hover:text-red-500 flex items-center print:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>              
              Salir
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Bottom = () => {
  return (
    <div className="bottom absolute mx-auto bottom-1">
      <div className="flex items-center p-1 text-white">
        <div className="text-center">
          <p className="text-gray-600 text-sm">
            <span>Bomberos de León &reg;. Todos los derechos reservados.</span>
            <span className="mx-4 text-gray-50">/</span>
            <a href="https://www.bomberosdeleon.org/aviso-de-privacidad.html" className="text-blue-500 hover:text-blue-700" target="_blank">Aviso de privacidad</a>
          </p>
        </div>        
      </div>
    </div>
  )
}