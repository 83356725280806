import React, { useState, useEffect} from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { Top } from "../Common/Header";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import './Schedule.css';

function Done ()  {	
	const location = useLocation();
  	const { course_name } = location.state;
  	const { course_id } = location.state;
  	const { date_selected } = location.state;
  	const { hour_selected } = location.state;
  	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);  	
  	let navigate = useNavigate();  	
  	
  	useEffect(() => {
		const user = AuthService.getCurrentUser();		
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, []);
 
	const handleConfirmation = (e) => {
		e.preventDefault();			
	};


	return (
		<div className="w-full min-h-screen mx-auto overflow-y-hidden">
			<Top />
					
			<div className="min-h-screen flex flex-col items-center justify-center -mt-16">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }

				<h1 className="text-3xl text-gray-400 font-bold">Confirmación</h1>
				<h3 className="text-2xl my-2">Curso: <strong className="" title={course_id}>{course_name}</strong></h3>
				<h3 className="text-2xl my-2">Fecha: <strong className="">{date_selected}</strong></h3>
				<h3 className="text-2xl my-2">Hora: <strong className="">{hour_selected}</strong></h3>
				<h2 className="text-lg mb-2">Confirmar curso</h2>

				<Link to="/done"
					state={{ course_name: course_name, course_id: course_id, date: date_selected, hour: hour_selected }} 
					className="block mt-4 border border-red-700 rounded-full text-white font-bold py-2 px-4 bg-red-700 hover:bg-red-900">
					Siguiente →
				</Link>

			</div>
		</div>
	);
};

export default Done;