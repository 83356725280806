import React, { useState, useEffect, useRef} from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { Top } from "../Common/Header";
import { Message } from "../Common/Message";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import './Confirmation.css';

const required = (value) => {
	if(!value){
		return (
			<div className="alert alert-danger" role="alert">
				Este campo es requerido!
			</div>
		);
	}
};

const logOut = () => {
  AuthService.logout();
};

function Capitalize(str){
	return str.charAt(0).toUpperCase() + str.slice(1);
};

function formatCurrency(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Confirmation = (props) => {
	const [isSubmitDisabled, setSubmitDisabled] = useState(false);	
	const location = useLocation();
	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false); 	
  const { course_name } = location.state;
  const { course_id } = location.state;
  const { qty } = location.state;
  const { hours_ori } = location.state;
  const { cost } = location.state;
  const { typepay } = location.state;
  const { date_selected } = location.state;
  const { date_selected_mx } = location.state;
  const { hour_selected } = location.state;
  const { modality_id } = location.state;
  const { group_name } = location.state;
  const { ubication } = location.state;
  const [ncost, setNcost] = useState(parseFloat(cost) * parseFloat(hours_ori));
  const [tpcost, setTpcost] = useState(parseFloat(cost) * parseFloat(qty));
  const [final_cost, setFinalCost] = useState(0);
  const [company, setCompany] = useState("");
  const [current_date, setCurrentDate] = useState("");
  const [message_form, setMessageForm] = useState("");
  const [register_id, setRegisterId] = useState("");
  const [successful, setSuccessful] = useState(false);
  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  const user = AuthService.getCurrentUser();
  const [role, setRole] = useState(undefined);  	
	useEffect(() => {  		
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		}else{
			setRole(user.role);
			if(role == "1" || role == "2"){
				//setNcost(cost);
			};			
		}
		
	  if(typepay == "Evento"){
	  	setFinalCost(ncost);
	  }else{
	  	setFinalCost(tpcost);
	  }	
	}, [typepay]);

	const disableSubmit = () => {
    setSubmitDisabled(true);        
  };
	useEffect(() => {
		UserService.getDataCompany()
		.then(
				(response) => {
					let datac = response.data;
        	setCompany(datac);
				},
				(error) => {
					const resMessage = ( error.response && error.response.data && error.response.data.message)
										|| error.message || error.toString();
					setMessageForm(resMessage);
					setSuccessful(false);
				}
			);				
	}, []);
	
 
	const handleConfirmation = (e) => {
		e.preventDefault();

		setMessageForm("");
		setSuccessful(false);
		setSubmitDisabled(true);		

		form.current.validateAll();		

		if(checkBtn.current.context._errors.length === 0){
			UserService.registerToCourse(course_id, modality_id, user.user_id, date_selected, hour_selected, qty, hours_ori, final_cost, group_name, ubication)
			.then(
				(response) => {
					setRegisterId(response.data.id);
					var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
					setSuccessful(true);
					var current = new Date().toLocaleDateString("es-MX", options);
					setCurrentDate(current);
					//navigate("/done");
					//window.location.reload(false);
				},
				(error) => {
					const resMessage = ( error.response && error.response.data && error.response.data.message)
										|| error.message || error.toString();
					setMessageForm(resMessage);
					setSuccessful(false);
				}
			);
		} // if checkBtn
	};


	function  printer(e){
    //e.preventDefault();
    const bodyElement = document.getElementsByTagName('body')[0];

    bodyElement.classList.add('printing');
    window.print();
    bodyElement.classList.remove('printing');
  };

	return (
		<div className="w-full min-h-screen mx-auto">
			<Top />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }			
				
				{!successful && (
				<div className="">
					<h1 className="mt-4 text-2xl text-red-500 uppercase pl-3">Resumen de</h1>
					<h1 className="mb-4 text-4xl border-2 border-red-500 text-red-500 font-bold uppercase py-2 px-4 inline-block">registro</h1>
				</div>
				)}
				<div className="flex flex-col items-center justify-center">
					{!successful && (
						<div className="mt-4">							
							<h3 className="my-4 font-bold text-xl uppercase">{modality_id < 3 ? "Curso": "Renta"}</h3>
							<div className="flex items-center my-2">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 inline-block mr-4" viewBox="0 0 20 20" fill="currentColor">
								  <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
								</svg>
								<h2 className="text-xl font-bold">{course_name}</h2>
							</div>
							<div className="flex items-center my-2">								
								<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								  <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
								</svg>
								<h2 className="text-xl">{Capitalize(date_selected_mx)}, {hour_selected}</h2>
							</div>
							<div className="flex items-center my-2">								
								<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								 	<path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
								<h2 className="text-xl">${ formatCurrency(final_cost) }. <span className="text-gray-500">Incluye IVA.</span></h2>
							</div>
							{modality_id == 3 &&(
							<div className="flex items-center my-2">								
								<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 inline-block mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								  <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
								<h2 className="text-xl">{ hours_ori } horas</h2>
							</div>
							)}
						</div>
					)}
					<Form onSubmit={handleConfirmation} ref={form}>
						<div className="flex flex-col">
							{!successful && (
							<div>							
								<Input type="hidden" name="course_id" 
											className="px-4 py-1 rounded-full border border-gray-700"
											value={course_id}
											validations={[required]} />
								<Input type="hidden"name="user_id"
											className="px-4 py-1 rounded-full border border-gray-700"
											value={user.user_id}										
											validations={[required]} />
								<div className="my-4">
			             		<button type="submit" disabled={isSubmitDisabled} className="w-full py-1 px-6 bg-red-500 hover:bg-red-700 text-2xl text-white uppercase font-bold rounded-full focus:outline-none focus:shadow-outline" style={isSubmitDisabled ? styles.disabledButton : styles.enabledButton}>Confirmar</button>
			          		</div>
							</div>
							)}
							<CheckButton style={{ display: "none" }} ref={checkBtn} />
						</div>
					</Form>

					{!successful && (
						<Link to="/home" className="text-red-500 font-bold mt-2 hover:text-red-700 hover:underline">Cancelar y empezar de nuevo</Link>
					)}

					{successful && (
					<div className="w-full mx-auto border my-8 p-4 relative printable" id="certificate">
						<div className="order px-4 pt-4 pb-4">
							<div className="flex gap-4 mb-2">
								<div><img src="/img/bomberos-de-leon.png" alt="BDL" className="h-8" /></div>
								<div>
									<h2 className="text-lg text-red-700 uppercase">Centro de Capacitación de Bomberos</h2>							
								</div>							
							</div>
							<div className="flex gap-4 items-center my-10">
								<div className="justify-center">
									<h1 className="text-xl text-gray-900 font-bold uppercase tracking-widest">Recibo de Pago</h1>
									<p className="uppercase text-sm">Documento informativo para trámite</p>
									<h1 className="text-xl text-gray-600 font-bold uppercase tracking-widest">{user.name}</h1>
								</div>
								<div className="ml-auto">
									<h1 className="text-xl text-gray-900 font-bold">No: {register_id}</h1>
									<h1 className="text-base text-gray-900">Fecha de emisión: {current_date}</h1>
								</div>
							</div>
							<div className="border-b border-gray-500 my-6 pb-6">
								<table className="table-fixed w-full">
									<tbody className="text-left">
										<tr className="bg-gray-50">
											<th className="pb-2 uppercase" title={course_id}>{modality_id < 3 ? "CURSO": "RENTA"}</th>
											<th className="pb-2 uppercase text-right">Total a Pagar</th>
										</tr>
										<tr>
											<td>
												<p className="font-bold">{course_name}</p>
												<p>Modalidad&nbsp;  
													{
														{
													   	'1': "Individual",
													      '2': "Grupal",
													      '3': "Renta"														     
													   }[modality_id]
													   || ""
													}
												</p>												
												<p><small>Fecha solicitada:</small><br />{ Capitalize(date_selected_mx) } - {hour_selected}</p>
											</td>
											<td className="text-right text-xl font-bold">
												${ formatCurrency(final_cost) }
												<p className="text-sm">Incluye IVA</p>
											</td>
										</tr>										
									</tbody>
								</table>
							</div>
							<div className="my-6 pb-4">
								<p className="my-2">Información de pago en banco</p>
								<table className="table-fixed w-full">
									<tbody className="text-left">
										<tr>
											<th className="text-gray-700">Banco:</th>
											<td>{company.bank}</td>
										</tr>
										<tr>
											<th className="text-gray-700">No. de cuenta:</th>
											<td>{company.account}</td>
										</tr>
										<tr>
											<th className="text-gray-700">CLABE:</th>
											<td>{company.clabe}</td>
										</tr>
										<tr>
											<th className="text-gray-700">Titular:</th>
											<td>{company.name}</td>
										</tr>								
									</tbody>
								</table>
							</div>
						</div>

						<div className="notes bg-gray-100 rounded py-4 px-6">
							<h3 className="font-bold">Notas:</h3>
							<ul className="list-disc">
								<li>Hasta que se realice el pago de esta órden, se confirmará al 100% la inscripción del registro.</li>
								<li>Cuando haya realizado el pago, inicie sesión en la plataforma <em className="text-sm font-semi-bold font-serif">https://cursos.bomberosdeleon.net</em> y vaya al menú "Mi Cuenta" para subir su comprobante de pago en el curso registrado.</li>
								<li>Si no se realiza el pago dentro de los siguientes <strong>3 días a partir de la generación de esta órden</strong>, se eliminará toda la información relacionada a la misma.</li>
								<li>En caso de no concluir el proceso en el tiempo indicado se cancela automáticamente su servicio.</li>
							</ul>							
						</div>

						<div className="print mt-4 flex justify-center print:hidden">
							<button onClick={() => printer()} className="block mt-4 border bg-red-500 border-red-700 rounded-full text-white font-bold py-2 px-4 hover:bg-red-900">Imprimir</button>							
						</div>
						<div className="print mt-2 mb-8 flex justify-center print:hidden">
							<Link to="/login" onClick="{logOut}" className="text-red-500 font-bold hover:text-red-700 hover:underline">Cerrar Sesión</Link>
						</div>
					</div>
					)}

				</div>
			</div>

		</div>
	);
};

export default Confirmation;


const styles = {
    disabledButton: {
        backgroundColor: 'silver',
    },
    enabledButton:
    {

    },
};