import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navigator from "../Common/Navigator";
import AuthService from "../../services/auth.service";

const Profile = () => {
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();
	useEffect(() => {
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, []);

	return (		
		<div className="w-full overflow-y-hidden">
			
			<Navigator />
			{user &&(
			<div className="w-full max-w-screen-xl min-h-screen mx-auto overflow-y-hidden">
				
				<div className="my-8 mx-auto items-center" >
					<header className="jumbotron">
						<h2 className="text-xl"><strong>{ user.name}</strong> Perfil</h2>
					</header>
					<p>
						<strong>Email:</strong> { user.email }
					</p>
					{/*<p>
						<strong>Token:</strong> { user.token.substring(0, 20)} .. {" "}
						{ user.token.substr(user.token.length - 20) }
					</p>*/}						
				</div>
			</div>
			)}
		</div>
	);
};

export default Profile;