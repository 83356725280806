import React, { useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import Navigator from "../Common/Navigator";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

const BoardIndividuals = () => {
	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);
	const [courses, setCourses] = useState([{ name: "Cargando ...", id: "" }]);
	const [messageCall, setMessageCall] = useState("");
	const [cname, setCname] = useState("noname");
	const [datetime, setDatetime] = useState("now");
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();	
	useEffect(() => {
		filtrate();
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, []);

	const filtrate = () => {
   //useEffect(() => {	
		UserService.getCoursesByUser(user.user_id, 1, datetime, cname).then(
			(response) => {
				setCourses([{ name: "Cargando ...", id: "" }]);
				setCourses(response.data.registers.map(({ id, course_name, date_requested, hour_requested, for_date, for_hour }) => ({ id: id, name: course_name, date_requested: formatDate(date_requested), hour_requested: hour_requested, for_date: formatDate(for_date), for_hour: for_hour })));
				if(response.data.registers.length == 0){
					setMessageCall("No se encontró información de cursos registrados.");
				}
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);
			}
		);
	//}, [datetime, cname]);
	}

	const onChangeCname = (e) => {
		const name_selected = e.target.value || "";
		if(name_selected){
			setCname(name_selected);
		}else{
			setCname("noname");
		}
	};

	const onChangeDatetime = (e) => {
		const date_selected = e.target.value || "";
		if(date_selected){
			setDatetime(date_selected);
		}else{
			setDatetime("now");
		}
	};

	function formatDate(dt){
		//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		//var datestring = new Date(dt).toLocaleDateString("es-MX", options);
		if(dt){			
			var d = new Date(dt.replace(/-/g, '\/').replace(/T.+/, ''));	//var d = new Date(dt);
			var datestring = d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
			return datestring;
		}
	};

	return (				
		<div className="w-full min-h-screen mx-auto overflow-y-hidden">
			<Navigator />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8 my-6">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }

				<div className="flex">
					<div className="flex-auto">
						<h2 className="text-2xl text-red-600 font-bold uppercase">Individuales</h2>
						<h2 className="text-lg text-gray-500">Cursos impartidos para público en general</h2>
					</div>
					<div className="ml-auto">
						<div className="flex items-center gap-2">
							<p className="text-sm whitespace-nowrap italic font-serif text-gray-600">Buscar por nombre/fecha</p>
							<input type="text" name="cname" onChange={onChangeCname} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  />
							<input type="date" name="datetime" onChange={onChangeDatetime} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  />
							<button name="filter" onClick={filtrate} className="font-bold text-base bg-blue-500 hover:bg-blue-700 text-white px-4 rounded focus:outline-none focus:shadow-outline">Filtrar</button>
							<a href="/individuals" title="Limpiar búsqueda">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
								  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
								</svg>
							</a>
						</div>
					</div>
				</div>
				<div className="px-4 mb-8 border">
				{messageCall ? <div className="p-4"><p className="text-sm text-gray-500">{ messageCall }</p></div> : "" }
				{courses.map(item => (					
						<div className="flex items-center my-2" key={item.id}>
							<div className="bg-gray-100 p-4 flex items-center justify-center rounded">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-red-500 inline-block"  fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								  <path d="M12 14l9-5-9-5-9 5 9 5z" />
								  <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
									<path strokeLinecap="round" strokeLinejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
								</svg>
							</div>
							<div className="ml-2">
								<Link to="/course" key={item.id} state={{ register_id: item.id, course_name: item.name, course_id: item.id }} className="block mt-2 hover:underline">
									<h2 className="font-bold text-xl">{item.name}</h2>
								</Link>
								<div className="flex">
									<div className="flex">
										<div className="flex items-center my-2">								
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											  <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
											</svg>
											<h2 className="text-base">{item.for_date}</h2>
										</div>									
										<div className="flex items-center ml-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											 	<path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
											</svg>
											<h2 className="text-base">{item.for_hour}</h2>										
										</div>
									</div>
									<div className="flex items-center ml-4">
										<p className="text-gray-500 px-2 italic font-serif">Fecha solicitada:</p>
										<div className="flex items-center my-2">																		
											<h2 className="text-gray-500 text-base italic font-serif">{item.date_requested}</h2>
										</div>									
										<div className="flex items-center my-2 ml-4">							
											<h2 className="text-gray-500 text-base italic font-serif">{item.hour_requested}</h2>										
										</div>
									</div>
									<div className="flex items-center ml-4">
										<span className="text-xs text-gray-300 px-2 italic font-serif">ID: {item.id}</span>
									</div>
								</div>
							</div>
						</div>											
			    ))}			    
				</div>
			</div>
		</div>
	);
};

export default BoardIndividuals;