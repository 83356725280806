import React, { useState, useEffect, useRef} from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { Top } from "../Common/Header";
import { Message } from "../Common/Message";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import './Ticket.css';

const Ticket = (props) => {	
	let navigate = useNavigate();	
	const user = AuthService.getCurrentUser();
	const [role, setRole] = useState(undefined);	

	const location = useLocation();
	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);
	const { register_id } = location.state;
	const [course, setCourse] = useState([{ id: "", name: "Cargando ...", content: "", date_requested: "", hour_requested: "", paid: "", modality_id: 0, qty: 1, hours: 1, cost: 0, in_training: 0 }]);    
  	const [company, setCompany] = useState("");
  	const [date_selected, setDateSelected] = useState("");
  	const [date_selected_mx, setDateSelectedMx] = useState("");
  	const [hour_selected, setHourSelected] = useState("");
  	const [ncost, setNcost] = useState(0);
  	const [current_date, setCurrentDate] = useState("");
  	//const [message_form, setMessageForm] = useState("");
  	//const [register_id, setRegisterId] = useState("");

	useEffect(() => {
		if(user) {
			setRole(user.role);
		}else{
			navigate("/login");
			window.location.reload(false);
			return;
		};

		UserService.getDataFromCourse(register_id).then(
			(response) => {
				let cdata = response.data.course;        
        		setCourse({id: cdata.id, name: cdata.name, content: cdata.content, date_requested: cdata.date_requested,  hour_requested: cdata.hour_requested, for_date: cdata.for_date, for_hour: cdata.for_hour, paid: cdata.paid, modality_id: cdata.modality_id, qty: cdata.qty, hours: cdata.hours, cost: cdata.cost, in_training: cdata.in_training});        
        		if(user.role == 1 || user.role == 2){
					setNcost(parseFloat(cdata.cost));
				}else{
					var cost = parseFloat(cdata.cost) || 0;
	        		var hours = parseFloat(cdata.hours) || 0;
	        		var total = parseFloat(cost) * parseFloat(hours);
	        		setNcost(total);
				};

	        	var current = new Date().toLocaleDateString("es-MX", options);
				setCurrentDate(current);

				var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
				let dat_req = cdata.date_requested.toString();
				var parts = dat_req.split('-'); // Please pay attention to the month (parts[1]); JavaScript counts months from 0: January - 0, February - 1, etc.
				var date_n = new Date(parts[0], parts[1] - 1, parts[2]); 				
				const dt = date_n.toISOString().split('T')[0];
				const dt_mx = date_n.toLocaleDateString("es-MX", options);
				setDateSelected(dt);
				setDateSelectedMx(dt_mx);
				setHourSelected(cdata.hour_requested);

				let datac = response.data.company;
	        	setCompany(datac);
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);
			}
		);
	}, []);

	function Capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	function formatCurrency(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	function  printer(e){
    //e.preventDefault();
    const bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.classList.add('printing');
    window.print();
    bodyElement.classList.remove('printing');
  };

	return (
		<div className="w-full min-h-screen mx-auto">
			<Top />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }			
				
				<Link to="/course" state={{ register_id: register_id }} defaultValue="" className="block mt-2 hover:underline">
					<span className="text-base text-red-600 font-bold">&lt;&lt; Regresar a curso</span>
				</Link>

				<div className="flex flex-col items-center justify-center">
					
					<div className="w-full mx-auto border my-8 p-4 relative printable" id="certificate">
						<div className="order px-4 pt-4 pb-4">
							<div className="flex gap-4 mb-2">
								<div><img src="/img/bomberos-de-leon.png" alt="BDL" className="h-8" /></div>
								<div>
									<h2 className="text-lg text-red-700 uppercase">Centro de Capacitación de Bomberos</h2>							
								</div>							
							</div>
							<div className="flex gap-4 items-center my-10">
								<div className="justify-center">
									<h1 className="text-xl text-gray-900 font-bold uppercase tracking-widest">Recibo de Pago:</h1>
									<p className="uppercase text-sm">Documento informativo para trámite</p>
									<h1 className="text-xl text-gray-900 font-bold uppercase tracking-widest">{user.name}</h1>
								</div>
								<div className="ml-auto">
									<h1 className="text-xl text-gray-900 font-bold">No: {register_id}</h1>
									<h1 className="text-base text-gray-900">Fecha de emisión: {current_date}</h1>
								</div>
							</div>
							<div className="border-b border-gray-500 my-6 pb-6">
								<table className="table-fixed w-full">
									<tbody className="text-left">
										<tr className="bg-gray-50">
											<th className="pb-2 uppercase" title={course.id}>{course.modality_id < 3 ? "CURSO": "RENTA"}</th>
											<th className="pb-2 uppercase text-right">Total a Pagar</th>
										</tr>
										<tr>
											<td className="w-2/3">
												<p className="font-bold">{course.name}</p>
												<p>Modalidad&nbsp;  
													{
														{
													   	'1': "Individual",
													      '2': "Grupal",
													      '3': "Renta"														     
													   }[course.modality_id]
													   || ""
													}
												</p>
												<p><small>Fecha solicitada:</small><br />{ Capitalize(date_selected_mx) } - {hour_selected}</p>
											</td>
											<td className="text-right text-xl font-bold">
												${ ncost }
												<p className="text-sm">Incluye IVA</p>
											</td>
										</tr>										
									</tbody>
								</table>
							</div>
							<div className="my-6 pb-4">
								<p className="my-2">Información de pago en banco</p>
								<table className="table-fixed w-full">
									<tbody className="text-left">
										<tr>
											<th className="text-gray-700">Banco:</th>
											<td>{company.bank}</td>
										</tr>
										<tr>
											<th className="text-gray-700">No. de cuenta:</th>
											<td>{company.account}</td>
										</tr>
										<tr>
											<th className="text-gray-700">CLABE:</th>
											<td>{company.clabe}</td>
										</tr>
										<tr>
											<th className="text-gray-700">Titular:</th>
											<td>{company.name}</td>
										</tr>									
									</tbody>
								</table>
							</div>
						</div>

						<div className="notes bg-gray-100 rounded py-4 px-6">
							<h3 className="font-bold">Notas:</h3>
							<ul className="list-disc">
								<li>Hasta que se realice el pago de esta órden, se confirmará al 100% la inscripción del registro.</li>
								<li>Cuando haya realizado el pago, inicie sesión en la plataforma <em className="text-sm font-semi-bold font-serif">https://cursos.bomberosdeleon.net</em> y vaya al menú "Mi Cuenta" para subir su comprobante de pago en el curso registrado..</li>
								<li>Si no se realiza el pago dentro de los siguientes <strong>7 días a partir de la generación de esta órden</strong>, se eliminará toda la información relacionada a la misma.</li>
								<li>En caso de no concluir el proceso en el tiempo indicado se cancela automáticamente su servicio.</li>									
							</ul>							
						</div>

						<div className="print mt-4 flex justify-center print:hidden">
							<button onClick={() => printer()} className="block mt-4 border bg-red-500 border-red-700 rounded-full text-white font-bold py-2 px-4 hover:bg-red-900">Imprimir</button>							
						</div>
					</div>					

				</div>
			</div>

		</div>
	);
};

export default Ticket;