import axios from 'axios';
import authHeader from './auth-header';
import authHeaderFile from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const upload = (register_id, file, typ, onUploadProgress) => {
  	let formData = new FormData();
  	formData.append("register_id", register_id);
  	formData.append("file", file);
    formData.append("typ", typ);

  	return axios.post(API_URL + "/upload", 
  		formData, 
  		{ headers: authHeaderFile() },
    	onUploadProgress,
  	);
};

const getFiles = () => {
  return axios.get(API_URL + "/files", { headers: authHeader()} );
};

export default { upload, getFiles };
