import React, { useState, useRef, Fragment } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link, useNavigate } from "react-router-dom";
import { Bottom } from "../Common/Header";
import AuthService from "../../services/auth.service";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import CookieConsent from "react-cookie-consent";

const required = (value) => {
	if(!value) {
		return(
			<div className="alert alert-danger" role="alert">
				Este campo es requerido!
			</div>
		)
	}
}

const Login = (props) => {	
	const form = useRef();
	const checkBtn = useRef();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	let navigate = useNavigate();

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage("");
		setLoading(true);

		form.current.validateAll();

		if(checkBtn.current.context._errors.length === 0) {
			AuthService.login(email, password)
			.then(
				() => {
					navigate("/home");
					window.location.reload(false);
				},(error) => {
					const resMessage = 
					(error.response && error.response.data && error.response.data.message) ||
					error.message || error.toString();

					setLoading(false);
					if (error.response.status === 401) {
					   setMessage("Usuario no encontrado");
					}else{
						setMessage(resMessage);
					}									
				}
			);
		}else{
			setLoading(false);
		}
	};

	const [open, setOpen] = useState(false)

  	const cancelButtonRef = useRef(null)

	return(
		<div className="min-h-screen bg-gray-100 flex items-center justify-center bg-cover" style={{ backgroundImage: "url('/img/banners/back-bomberos1.jpg')" }}>

			<div className="flex flex-row bg-gray-100 rounded shadow-lg">
				<div className="login shadow p-4 bg-white rounded-l">
					<Form onSubmit={handleLogin} ref={form} >
						<div className="flex flex-col">
							<div className="mb-4 flex flex-col justify-center text-center">
								<Link to="/"><img src="/img/bomberos-de-leon.png" alt="BDL" className="mx-auto" /></Link>
								<h2 className="text-lg mt-2">Portal de Cursos</h2>
							</div>					
							<div className="mb-2">
								<label className="block text-gray-700 text-sm mb-2">Correo electrónico</label>
								<Input type="email" name="email" 
										className="px-4 py-1 rounded-full border border-gray-700"
										value={email} 								
										onChange={onChangeEmail} 
										validations={[required]} />
							</div>
							<div className="mb-2">
								<label className="block text-gray-700 text-sm mb-2">Contraseña</label>
								<Input type="password" name="password" 
										className="px-4 py-1 rounded-full border border-gray-700"
										value={password} 								
										onChange={onChangePassword} 
										validations={[required]} />
							</div>
							<div className="my-2">
					        	<button className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline" type="submit" disabled={loading}>
					        		{loading && (
					           	<span className="spinner-border spinner-border-sm"></span>
					           	)}
					           	<span>Entrar</span>
					         </button>
				         </div>

				         {message && (
				         <div className="mb-4">
				        		<div className="text-red-500 text-xs italic" role="alert">
				            	{message}
				            </div>
				         </div>
				         )}				         

				      	<CheckButton style={{ style: 'none' }} ref={checkBtn} />
				   	</div>
			   	</Form>
			   </div>
			   <div className="register mx-4">			   	
			   	<div className="bg-white rounded my-2 p-4">
			   		<p className="my-2 text-sm text-gray-500 justified">Para inscribirte a cursos,<br /> debes contar con una cuenta.</p>
			   		<Link to="/register" className="text-blue-600 hover:text-blue-800 hover:underline">Registrar cuenta nueva</Link>			   		
			   	</div>			   	

			   	<div className="bg-white rounded my-8 p-4">
			   		<p className="my-2 text-sm text-gray-500 justified">Puedes consulta nuestras<br /> política de cookies.</p>
			   		<button
		                  type="button"
		                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
		                  onClick={() => setOpen(true)}
		                  ref={cancelButtonRef}
		                >
		                  Políticas de Cookies
		            </button>
		         </div>

		         <p className="text-gray-500 text-center text-xs">Centro de Capacitación de Bomberos&reg;</p>
			   </div>
		   </div>
			
			<Bottom />

			<Transition.Root show={open} as={Fragment}>
		      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
		        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
		          <Transition.Child
		            as={Fragment}
		            enter="ease-out duration-300"
		            enterFrom="opacity-0"
		            enterTo="opacity-100"
		            leave="ease-in duration-200"
		            leaveFrom="opacity-100"
		            leaveTo="opacity-0"
		          >
		            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
		          </Transition.Child>

		          {/* This element is to trick the browser into centering the modal contents. */}
		          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
		            &#8203;
		          </span>
		          <Transition.Child
		            as={Fragment}
		            enter="ease-out duration-300"
		            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		            enterTo="opacity-100 translate-y-0 sm:scale-100"
		            leave="ease-in duration-200"
		            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
		            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		          >
		            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
		              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
		                <div className="sm:flex sm:items-start">
		
		                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
		                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
		                      Política de Cookies
		                    </Dialog.Title>
		                    <div className="mt-2 max-h-60 overflow-y-auto">		                      										
										<p>En Patronato de Bomberos de Le&oacute;n, Gto, valoramos tu privacidad y queremos que est&eacute;s informado sobre c&oacute;mo utilizamos las cookies en nuestro sitio. Esta pol&iacute;tica explica qu&eacute; son las cookies, c&oacute;mo las utilizamos, y c&oacute;mo puedes gestionarlas.</p>
										<p><strong>&iquest;Qu&eacute; son las cookies?</strong></p>
										<p>Las cookies son peque&ntilde;os archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Estas cookies permiten al sitio recordar tus preferencias y acciones durante un per&iacute;odo de tiempo, lo que facilita una navegaci&oacute;n m&aacute;s eficiente y personalizada.</p>
										<p><strong>&iquest;C&oacute;mo utilizamos las cookies?</strong></p>
										<p>En Patronato de Bomberos de Le&oacute;n, Gto, utilizamos cookies para diversos fines, tales como:</p>
										<ol>
										<li><strong>Cookies de Funcionalidad</strong>: Para recordar tus preferencias y ajustes, como el idioma y la regi&oacute;n.</li>
										<li><strong>Cookies de Rendimiento</strong>: Para recopilar informaci&oacute;n sobre c&oacute;mo los usuarios interact&uacute;an con el sitio, ayud&aacute;ndonos a mejorar su funcionamiento. Esto incluye datos sobre las p&aacute;ginas visitadas, el tiempo de permanencia, y otros datos estad&iacute;sticos.</li>
										<li><strong>Cookies de Publicidad</strong>: Para ofrecerte anuncios relevantes y medir la efectividad de nuestras campa&ntilde;as publicitarias.</li>
										</ol>
										<p><strong>&iquest;C&oacute;mo puedes gestionar las cookies?</strong></p>
										<p>Puedes controlar y gestionar las cookies a trav&eacute;s de la configuraci&oacute;n de tu navegador. La mayor&iacute;a de los navegadores permiten bloquear o eliminar cookies, as&iacute; como configurar notificaciones cuando se env&iacute;an cookies a tu dispositivo. Ten en cuenta que al desactivar las cookies, algunas funcionalidades de nuestro sitio podr&iacute;an no funcionar correctamente.</p>
										<p><strong>&iquest;Qu&eacute; sucede si no acepto las cookies?</strong></p>
										<p>Si decides no aceptar cookies, puedes seguir navegando por el sitio, pero tu experiencia puede verse afectada. Algunas caracter&iacute;sticas del sitio pueden no estar disponibles o no funcionar de manera &oacute;ptima.</p>
										<p><strong>Actualizaciones a nuestra pol&iacute;tica de cookies</strong></p>
										<p>Nos reservamos el derecho de actualizar esta pol&iacute;tica en cualquier momento para reflejar cambios en nuestras pr&aacute;cticas o en las leyes aplicables. Te recomendamos revisar esta p&aacute;gina peri&oacute;dicamente para estar al tanto de cualquier modificaci&oacute;n.</p>
										<p><strong>Contacto</strong></p>
										<p>Si tienes preguntas sobre nuestra pol&iacute;tica de cookies, no dudes en contactarnos en contacto@bomberosdeleon.org o al (477) 7735313.</p>
		                    </div>
		                  </div>
		                </div>
		              </div>
		              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
		                <button
		                  type="button"
		                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
		                  onClick={() => setOpen(false)}
		                  ref={cancelButtonRef}
		                >
		                  Cerrar
		                </button>
		              </div>
		            </div>
		          </Transition.Child>
		        </div>
		      </Dialog>
		    </Transition.Root>


		    <CookieConsent
				  location="bottom"
				  buttonText="Acepto"
				  cookieName="loginCookie"
				  style={{ background: "#2B373B" }}
				  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				  expires={150}
				>
				  Para ofrecerte una mejor experiencia utilizamos cookies en nuestro sitio web. Al continuar navegando, aceptas el uso de cookies de acuerdo con nuestra <span onClick={() => setOpen(true)} className="underline hover:cursor-pointer">Política de Cookies</span>.{" "}
				  <span style={{ fontSize: "10px" }}>Bomberos de León</span>
				</CookieConsent>
		</div>
	);
};


export default Login;