import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import AuthService from "../../services/auth.service";

const navigation = [
  {
    name: 'Portal de Inicio', href: '/home', current: false, outer: true, inner: false
  },
  {
    name: 'Panel de Control', href: '/dashboard', current: false, outer: false, inner: true
  },
  { 
    name: 'Cursos Individuales', href: '/individuals', current: false, outer: false, inner: false
  },
  { 
    name: 'Cursos Grupales', href: '/groups', current: false, outer: false, inner: false
  },
  { 
    name: 'Renta de Campo', href: '/consultants', current: false, outer: false, inner: false
  },
  { 
    name: 'Archivo', href: '/archived', current: false, outer: false, inner: false
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navigator () {
   //const [showGroupBoard, setShowGroupBoard] = useState(false);
   //const [showConsultantBoard, setShowConsultantBoard] = useState(false);   
    let navigate = useNavigate();  
    const user = AuthService.getCurrentUser();  
    useEffect(() => {
      if(!user) {     
        navigate("/login");
        window.location.reload(false);
      };
    }, []);         

   const logOut = () => {
      AuthService.logout();
   };

   return (
   <div className="w-full mx-auto print:hidden">
      <Disclosure as="nav" className="bg-gray-800">
         {({ open }) => (
           <>
             <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
               <div className="relative flex items-center justify-between h-16">
                 <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                   {/* Mobile menu button*/}
                   <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                     <span className="sr-only">Open main menu</span>
                     {open ? (
                       <XIcon className="block h-6 w-6" aria-hidden="true" />
                     ) : (
                       <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                     )}
                   </Disclosure.Button>
                 </div>
                 <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                   <div className="flex-shrink-0 flex items-center">
                     <Link to="/">
                        <img className="block lg:hidden h-8 w-auto" src="/img/bomberos-de-leon.png" alt="Bomberos de León" />
                     </Link>
                     <Link to="/">
                        <img className="hidden lg:block h-8 w-auto" src="/img/bomberos-de-leon.png" alt="Bomberos de León" />
                     </Link>
                   </div>
                   <div className="hidden sm:block sm:ml-6">
                     <div className="flex space-x-4">
                       {navigation.map((item) => (
                         <a
                           key={item.name}
                           href={item.href}
                           className={classNames(
                             item.current ? 'bg-gray-900 text-white' : 'hover:bg-gray-700 hover:text-white',
                             item.outer ? 'text-yellow-500' : 'text-gray-300',
                             item.inner ? 'text-blue-500' : 'text-gray-300',
                             'px-3 py-2 rounded-md text-sm font-medium'
                           )}
                           aria-current={item.current ? 'page' : undefined}
                         >
                           {item.name}
                         </a>
                       ))}
                     </div>
                   </div>
                 </div>
                 <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                   <button
                     type="button"
                     className="bg-gray-800 p-1 rounded-full text-sm text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                   >
                    {user && (
                      <div>
                        <span className="sr-only">{user.name}</span>
                        <span>{user.name}</span>
                      </div>
                      )
                    }
                   </button>
                  
                   {/* Profile dropdown */}
                   <Menu as="div" className="ml-3 relative">
                     <div>
                       <Menu.Button className="pl-2 pr-2 pt-1 pb-1 bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Abrir Menú Usuario</span>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                          </svg>
                          Mi Cuenta                         
                       </Menu.Button>
                     </div>
                     <Transition
                       as={Fragment}
                       enter="transition ease-out duration-100"
                       enterFrom="transform opacity-0 scale-95"
                       enterTo="transform opacity-100 scale-100"
                       leave="transition ease-in duration-75"
                       leaveFrom="transform opacity-100 scale-100"
                       leaveTo="transform opacity-0 scale-95"
                     >
                       <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">                         
                         <Menu.Item> 
                           {({ active }) => (
                             <a
                               href="/dashboard"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}                                
                             >
                               Panel de control
                             </a>
                           )}
                         </Menu.Item>
                         <Menu.Item> 
                           {({ active }) => (
                             <a
                               href="/profile"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}                                
                             >
                               Perfil
                             </a>
                           )}
                         </Menu.Item>
                         <Menu.Item> 
                           {({ active }) => (
                             <a
                               href="#"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                onClick={logOut}
                             >
                               Salir
                             </a>
                           )}
                         </Menu.Item>
                       </Menu.Items>
                     </Transition>
                   </Menu>
                 </div>
               </div>
             </div>

             <Disclosure.Panel className="sm:hidden">
               <div className="px-2 pt-2 pb-3 space-y-1">
                 {navigation.map((item) => (
                   <Disclosure.Button
                     key={item.name}
                     as="a"
                     href={item.href}
                     className={classNames(
                       item.current ? 'bg-gray-900 text-white' : 'hover:bg-gray-700 hover:text-white',
                       item.outer ? 'text-yellow-600' : 'text-gray-300',
                       'block px-3 py-2 rounded-md text-base font-medium'
                     )}
                     aria-current={item.current ? 'page' : undefined}
                   >
                     {item.name}
                   </Disclosure.Button>
                 ))}
               </div>
             </Disclosure.Panel>
           </>
         )}
      </Disclosure>
   </div>
   )
}